<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="stepbox one">
          <div class="line"></div>
          <div class="now"></div>
          <div class="step stepone ok">
            <span><i class="icon-stepone"></i></span>
            <p>1、选择票种</p>
          </div>
          <div class="step steptwo f_s">
            <span><i class="icon-steptwo"></i></span>
            <p>2、填写游客信息</p>
          </div>
          <div class="step stepthree f_s">
            <span><i class="icon-stepthree"></i></span>
            <p>3、确认支付</p>
          </div>
          <div class="step five f_s">
            <span><i class="icon-stepfive"></i></span>
            <p>4、购票成功</p>
          </div>
        </div>
        <div class="ticket">
          <div class="left">
            <div class="box">
              <div class="title">
                <h1><span>商品查询</span></h1>
              </div>
              <div class="query mt_12">
                <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
                  <el-form-item label="商品编码">
                    <el-input placeholder="商品编码" v-model="searchInfo.groupCode"></el-input>
                  </el-form-item>
                  <el-form-item label="商品名称">
                    <el-input placeholder="商品名称" v-model="searchInfo.groupName"></el-input>
                  </el-form-item>
                  <el-form-item label="商品类型">
                    <el-select placeholder="请选择商品类型" v-model="searchInfo.bizType">
                      <el-option label="全部" value=""></el-option>
                      <el-option v-for="(item,index) in typeArr" :label="item.name" :value="item.value" :key="index"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="searchInfo = {groupCode:'',groupName:'',bizType:''}">重置</el-button>
                    <el-button type="primary" @click="search">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="box">
              <div class="title">
                <h1><span>商品列表</span></h1>
              </div>
              <div class="list">
                <table>
                  <thead>
                  <tr class="gray">
                    <th>商品编码</th>
                    <th>商品名称</th>
                    <th>使用日期</th>
                    <th>渠道价</th>
                    <th>预订</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- <tr><td colspan="4"><p class="nodata"><img src="images/loading.gif" alt="数据加载中" title="数据加载中" /></p></td></tr> -->
                  <tr v-for="(item,index) in list" :key="index" :class="index%2 == 0 ? '' : 'gray'">
                    <td>{{item.ticketGroupCommodity.groupCode}}</td>
                    <td>{{item.ticketGroupCommodity.groupName}}</td>
                    <td>{{item.ticketGroupCommodity.dateRange}}</td>
                    <td>￥{{item.ticketGroupCommodity.showPrice ? Number(item.ticketGroupCommodity.showPrice/100).toFixed(2) : 0}}</td>
                    <td>
                      <a href="javascript:;" @click="checkTicket(item,index)" :class="item.checked ? 'checked' : ''"><i></i>预订</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title">当前已选</div>
            <div class="list">
              <p v-if="!chooseList.length">暂无选票</p>
              <template v-else>
                <div class="d_f ali_c j_s" v-for="(item,index) in chooseList" :key="index">
                  <span>{{item.ticketGroupCommodity.groupName}}</span>
                  <span>￥{{item.ticketGroupCommodity.sellPrice ? Number(item.ticketGroupCommodity.sellPrice/100).toFixed(2) : 0}}</span>
                </div>
              </template>
            </div>
            <div class="btn">
              <a href="javascript:;" class="notice" @click="showNotice = true"
              ><span><i></i>预订须知</span></a
              >
              <a href="javascript:;" @click="nextStep">下一步</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" v-show="showNotice">
      <div class="box">
        <div class="title">
          <a href="javascript:;" @click="showNotice = false" title="关闭">关闭</a>
          <span>预订须知</span>
        </div>
        <div class="content noticebox" >
          <div class="notice">
            <h1>铁定溜溜门票预订须知</h1>
            <p>优待票：（1）儿童：1.2米≤身高≤1.5米（2）长者：60周岁≤年龄＜70周岁（3）残障人士、现役军人（凭有效身份证件）
              预定时间：全天可售，可预定当日门票（08:30-21:00期间取票）。
              取票方式：请您凭入园辅助码、二维码、至景区售票处或游客服务中心自助取票机换取门票入园。
              退款规程：游玩日期当日不可申请退款，系统会自动拒绝，只要未使用，除游玩日当天任何一天都可申请。退款不收取手续费，不支持部分退款，一经取票，不退不换。
              1.身高1.2米以下小朋友和70周岁以上老人免票（凭有效身份证件）虎溜溜项目除外，入园需要购票成人陪同，每名购票成人仅可携带一名免票人员（成人标准：身高1.5米以上）</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "ticket",
    data() {
      return {
        showNotice: false, // 显示订票须知
        typeArr:[],
        searchInfo:{
          groupCode:'',
          groupName:'',
          bizType:'',
          queryType:'STIFF',
        },
        list:[],
        chooseList:[],
      };
    },
    created() {
      this.queryDisGroupBizType();
      this.search()
    },
    methods: {
      //获取商品列表
      search(){
        this.$api.queryDisGroupList(this.searchInfo).then(res => {
          this.list = res.data;
          this.list.map(item => {
            item.checked = false;
          })
        })
      },
      //查询商品类型
      queryDisGroupBizType(){
        this.$api.queryDisGroupBizType().then(res => {
          this.typeArr = [];
          res.data.map(item => {
            if([ 10,11,12,13 ].indexOf(Number(item.value))>-1){
              this.typeArr.push(item)
            }
          })
        })
      },
      // 选择门票
      checkTicket(item,index) {
        if(!item.ticketGroupCommodity.dateRange) return this.$message.error('分销商暂不支持自定义日期期票的购买！');
        this.list[index].checked = !this.list[index].checked;
        this.list = JSON.parse(JSON.stringify(this.list));
        this.chooseList = [];
        this.list.map(item => {
          if(item.checked){
            this.chooseList.push(item)
          }
        })

      },
      // 下一步
      nextStep() {
        let str = [];
        if(!this.chooseList.length) return this.$message.error('请选择商品！');
        this.chooseList.map(item => {
          str.push(item.ticketGroupCommodity.groupCommodityId)
        });
        this.$router.push({path:'/fillInQP',query:{ids:str.join(',')}})
      }
    }
  };
</script>

<style scoped>
  @import "../assets/css/step.css";
  @import "../assets/css/ticket.css";
  .main .ticket .left .box .list td:nth-child(3){
    color: #333333;
  }
  .main .ticket .left .box .list th:nth-child(2), .main .ticket .left .box .list td:nth-child(2){
    width: 35%;
  }
</style>
